import React from 'react'


const Feature = props => {
    const { image={}, header, description  } = props;
    return (
        <section className="spotlight">
        <div className="image">
          <img src={image.file ? image.file.url : ''} alt={image.description} />
        </div>
        <div className="content">
          <h2>
           {header}
          </h2>
          <p>
            {description}
          </p>
        </div>
      </section>
    )
}

export default Feature
