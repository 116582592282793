import React from 'react'

const Button = props => {

    const { linksTo, colour, content } = props;
    return (
        <a href={linksTo} className="button fit primary" style={{color: colour ? colour : ""}}>
              { content }
        </a>
    )
}


export default Button
