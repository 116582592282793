import React from 'react'


const Service = props => {
    const { iconCode, header, services } = props;
    return (
        <li className={`icon solid ${iconCode}`}>
            <h3>{header}</h3>
            { 
                services.length > 0 ? 
            <p>
                { React.Children.toArray(services.map(service => <>{service} <br /></>)) }
            </p>
            : ""
             }
        </li>
    )
}

export default Service
