import React from 'react'
import ButtonScroll from './ButtonScroll'
import Icon from './Icon'
import Feature from './Feature'
import Service from './Service'
import Button from './Button'


const contentfulComponents = {
    ContentfulButtonScroll: (props) => <ButtonScroll {...props}/>,
    ContentfulIcon: (props) => <Icon {...props}/>,
    ContentfulFeature: (props) => <Feature {...props}/>,
    ContentfulService: (props) => <Service {...props}/>,
    ContentfulButton: (props) => <Button {...props}/>
}

export const Component = ({ component={}, componentProps={} }) => {

    const selected = component.__typename

    const props = {...component, ...componentProps}

    if (typeof selected === 'undefined') return <div>No component</div>

    return contentfulComponents[component.__typename](props)
}

const MultiComponent = (array) => {
    if (array.length > 0) {
        return React.Children.toArray(array.map(type => <Component component={type}/>) )
    } 
    return <div>No Data</div>
}

export default MultiComponent