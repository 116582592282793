import React from 'react';
import SEO from '../components/seo'

import Layout from '../components/Layout';
import { graphql } from 'gatsby'
import { makeTitle } from '../functions/Utils'
import Header from '../components/Header';
import MultiComponent from '../components/MultiComponent';

const Success = ({data}) => {
  const { contentfulPage } = data

  const one = contentfulPage.content[0]
  return (
<Layout fullMenu backgroundImage={data.contentfulPage.image}>
    <SEO title={makeTitle(one.header)} />
    <article id={"main"} >
      <Header 
        className="about"
        style={{ backgroundPosition: '50% -20%', minHeight: '100vh', alignItems: "center"}}
        image={contentfulPage.image ? contentfulPage.image.file.url : ""}
      >
        <h2>{one.header}</h2>
        <h3>{one.subHeader}</h3>
        { MultiComponent(one.content) }
      </Header>
    </article>
  </Layout>
  )
}

export const query = graphql`
query SuccessPageQuery {
  contentfulPage(url: {eq: "/success"}) {
    id
    name
    image {
      file {
        url
      }
      title
    }
    content {
      ... on ContentfulSection {
        id
        name
        header
        subHeader
        content {
            ... on ContentfulButton {
              id
              linksTo
              content
              internal {
                type
              }
            }
          }
      }
    }
  }
}

`

export default Success;
