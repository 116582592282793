import React from 'react'
import Scroll from './Scroll';

export default function ButtonScroll({ content, scrollTo }) {
  return <ul className="actions special">
    <li>
      <Scroll type="id" element={scrollTo}>
        <a href="/#" className="button primary">
          {content}
        </a>
      </Scroll>
    </li>
  </ul>
}


ButtonScroll.defaultProps = {
  content: "test",
  scrollTo: "one"
}