import React from 'react'

const Icon = props => {


    const { colour, iconCode } = props;

    const colours = {
      Teal: "style1",
      Green: "style2",
      Blue: "style3",
    }
    return (
        <li>
          <span className={`icon solid ${iconCode || "fa-file"} major ${colour ? colours[colour]: "style1"}`}>
          </span>
        </li>
    )
}

export default Icon
