import React from 'react'

const Header = (props) => {
    const { children, image, style, className} = props;
    return (
        <header 
        className={className}
        style={{ 
          minHeight: '50vh',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          backgroundImage: `url(${image})`,
          backgroundPosition: '50% 50%',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          ...style
     }}>
      { children }
      </header>
    )
}


export default Header